/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";
@import "../node_modules/jsoneditor/dist/jsoneditor.min.css";
@import "../node_modules/flag-icons/css/flag-icons.min.css";

:host ::ng-deep json-editor,
:host ::ng-deep json-editor .jsoneditor,
:host ::ng-deep json-editor > div,
:host ::ng-deep json-editor jsoneditor-outer {
  height: 1000px;
}

.jsoneditor-mode-code {
  height: 1000px;
}

@primary-color: #1990FF;
@border-radius-base: 8px;
@modal-content-bg: #FAFAFA;
@descriptions-bg: #f3f2f2;


.back-icon {
  font-size: 25px;
  cursor: pointer;
}

html, body {
  background: #ececec;
  scroll-behavior: smooth;
}

nz-select {
  width: 100%;
}

nz-date-picker {
  width: 100%;
}

.back-icon {
  font-size: 25px;
  cursor: pointer;
}

.icon-20 {
  font-size: 18px !important;
}

.m-1 {
  margin: 4px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}


.mb-3 {
  margin-bottom: 30px;
}


.mb-4 {
  margin-bottom: 40px;
}


.mb-5 {
  margin-bottom: 50px;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.w-100 {
  width: 100%;
}

.mt-3 {
  margin-top: 30px;
}

.mt-4 {
  margin-top: 40px;
}

.mt-5 {
  margin-top: 50px;
}

.mr-1 {
  margin-right: 10px;
}

.mr-2 {
  margin-right: 20px;
}

.mr-3 {
  margin-right: 30px;
}

.mr-4 {
  margin-right: 40px;
}

.mr-5 {
  margin-right: 50px;
}

.ml-1 {
  margin-left: 10px;
}

.ml-2 {
  margin-left: 20px;
}

.ml-3 {
  margin-left: 30px;
}

.ml-4 {
  margin-left: 40px;
}

.ml-5 {
  margin-left: 50px;
}

.mg-1 {
  margin: 0 10px;
}

.mg-2 {
  margin: 0 20px;
}

.mg-3 {
  margin: 0 30px;
}

.mg-4 {
  margin: 0 40px;
}

.mg-5 {
  margin: 0 50px;
}

.mv-1 {
  margin: 10px 0;
}

.mv-2 {
  margin: 20px 0;
}

.mv-3 {
  margin: 30px 0;
}

.mv-4 {
  margin: 40px 0;
}

.mv-5 {
  margin: 50px 0;
}

.p-0-5 {
  padding: 5px;
}

.text-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.align-items-center {
  align-items: center;
}

.text-left {
  text-align: left;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.text-end {
  text-align: end;
}

.action_eye_icon {
  cursor: pointer;
  font-size: 22px;
  font-weight: 800;
}

.highlight_blue {
  background: #1890FF;
  padding: 5px 10px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 2px;
  border-radius: 5px;
  font-size: 13px;
}

.highlight_yellow {
  background: #e5b447;
  padding: 5px 10px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 2px;
  border-radius: 5px;
  font-size: 13px;
}

.highlight_gray {
  background: #B8B8B8;
  padding: 5px 10px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 2px;
  border-radius: 5px;
  font-size: 13px;
}

.highlight_green {
  background: #3CB371;
  padding: 5px 10px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 2px;
  border-radius: 5px;
  font-size: 13px;
}

.highlight_red{
  background: #FF4D4F;
  padding: 5px 10px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 2px;
  border-radius: 5px;
  font-size: 13px;
}

.text_red{
  color: #FF4D4F;
}

.text_green{
  color: #3CB371;
}

.text_blue{
  color: #1890FF;
}

.btn_green {
  border-color: mediumseagreen; background-color: mediumseagreen
}

.cursor-pointer {
  cursor: pointer;
}
